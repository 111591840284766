<template>
    <div>
        <!-- 제목 -->
        <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
            <font class="font-weight-bold text-h6 grey--text text--darken-2">FAQ 관리 <v-icon class="mb-3px">mdi-chevron-right</v-icon> 등록하기</font>
        </v-sheet>

        <!-- 본문 -->
        <div
            class="d-flex"
        >
            <v-sheet
                width="1000"
                class="pa-2 ma-2"
                outlined
            >
                <div class="d-flex">
                    <!-- 제목 -->
                    <v-sheet
                        class="pa-1 mt-2 mr-2 rounded-10"
                        outlined
                    >
                        <v-select
                            solo
                            flat
                            dense
                            hide-details
                            :items="select_list.category"
                            placeholder="카테고리"
                            v-model="category"
                        ></v-select>
                    </v-sheet>

                    <!-- 제목 -->
                    <v-sheet
                        width="100%"
                        class="pa-1 mt-2 rounded-10"
                        outlined
                    >
                        <v-text-field
                            hide-details
                            dense
                            placeholder="제목을 입력해주세요"
                            solo
                            flat
                            v-model="title"
                        ></v-text-field>
                    </v-sheet>
                </div>

                <!-- 본문 -->
                <TipTapWriter
                    class="tiptap_style mt-2"
                    :options="options"
                />

                <!-- 버튼 -->
                <v-sheet class="d-flex justify-center pa-1 mt-2">
                    <v-btn
                        class="mx-2 px-10 rounded-10"
                        color="#ADAFCA"
                        dark
                        large
                        depressed
                        @click="cancel()"
                    >
                        취소하기
                    </v-btn>
                    <v-btn
                        class="mx-2 px-10 rounded-10"
                        color="primary"
                        dark
                        large
                        depressed
                        @click="submit()"
                    >
                        등록하기
                    </v-btn>
                </v-sheet>
            </v-sheet>
        </div>
    </div>
</template>
<script>
import TipTapWriter from "@/components/tiptap/Writer"

export default {
    components: {
        TipTapWriter
    },

    data: () => ({
        select_list: {
            category: [
                "계정 및 로그인",
                "견적 요청 관련",
                "업체 관련",
                "기타"
            ]
        },

        category: '',
        title: '',

        //TipTap
        options: {
            content: '',
            editable: true,
            supportImage: true,
            supportVideo: true
        },
    }),

    methods: {
         // DB 저장
        insert: _.debounce(async function() {
            this.$http.post('/api/admin/faq/insert', {
                params: {
                    category: this.category,
                    title: this.title,
                    content: this.options.content
                }
            }).then(res => {
                this.$router.push("/admin/faq/list")
            })
        }, 500),

        // 취소하기
        cancel(){
            if(confirm("지금 작성하고 계신 글이 저장되지 않습니다.\n페이지를 이동하시겠습니까?")){
                this.$router.go(-1)
            }
        },

        // 등록하기
        async submit(){
            if(this.category == "")
            {
                alert("카테고리를 선택해주세요")
            }
            else if(!this.title.length)
            {
                alert("제목을 입력해주세요")
            }
            else if(!this.options.content.length)
            {
                alert("내용을 입력해주세요")
            }
            else
            {
                // DB 저장
                await this.insert()
            }
        }
    }
}
</script>
<style scoped>
.table_style{
    width:100%;
    background:white;
    border:1px solid #ddd;
    border-collapse: collapse;
}

.table_style tr td{
    border:1px solid #ddd;
    padding:4px;
    text-align: center;
}

.filled{
    background:#ddd;
    color:#999;
    font-weight:300;
}

.reservation{
    background:#bba8ca;
    color:#84679a;
    font-weight:300;
}
</style>

<style scoped>
.my-clipper {
    width:100%;
}

.placeholder {
    width:100%;
    height:150px;
}
</style>